<script>
import swal from 'sweetalert2';
import VButton from '@/components/Button.vue';
/* import VIcon from '@/components/Icon.vue'; */
import VTextbox from '@/components/Textbox.vue';
import SignIn from '@/views/SignIn.vue';

export default {
  components: {
    VButton,
    /* VIcon, */
    VTextbox,
    SignIn
  },
  data() {
    return {
      card: '',
      cpf: '',
      cnpj: '',
      type: 'PF',
      userExists: false,
      cadastroExistente: null,
      cardType: 'Cartão Físico',
      documentType: '',
      codeSended: '',
    };
  },
  methods: {
    unlock() {
      if (!this.checkLocalStorageAvaliable()) {
        swal.fire({
          title: 'Cookies de terceiro bloqueados',
          html: `Por favor,
          ative os cookies de terceiros nas configurações do seu navegador.
          Siga <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR&oco=1">
          este passo-a-passo</a> para desbloquear os cookies e utilizar o site OnlyPay.`,
          type: 'error',
          confirmButtonText: 'Ok'
        })
        return false
      }
      if (!this.$store.state.formLoading) {
        this.$store.commit('formLoading', true);
        this.$validator.validateAll().then((valid) => {
          if (valid && this.isValidFields()) {
            const cpf = this.cpf.replace(/\.|-/g, '')
            const cnpj = this.cnpj.replace(/\.|-|\//g, '')
            const document = this.type === 'PF' ? cpf : cnpj;
            const codeSended = this.codeSended.replace(/\.|-/g, '')
            // const urlForm = `/users/Unlock?document=${document}&cardNumber=${this.card}`;

            const validateCode = () => {
              if (this.cardType === 'Cartão Virtual') {
                this.$http.post('users/create-virtualcard-phoneprocess-validateotc', { codeSended, document })
                // eslint-disable-next-line no-unused-vars
                  .then(({ data }) => {
                    if (this.type === 'PF') {
                      this.documentType = this.type;
                      this.$store.dispatch('setDocumentType', this.documentType);
                      this.$store.dispatch('setCardUnlockCode', codeSended);
                      this.$store.commit('setUser', data);
                    } else if (this.type === 'PJ') {
                      this.documentType = this.type;
                      this.$store.dispatch('setDocumentType', this.documentType);
                      this.$store.dispatch('setCardUnlockCode', codeSended);
                      this.$store.commit('setUser', data);
                    }

                    this.$router.push({ name: 'VirtualCardSignUp' });
                  }).catch(err => {
                    if (err) {
                      swal.fire({
                        title: 'Código inválido!',
                        text: 'Por favor, insira um novo código.',
                        type: 'info',
                        confirmButtonText: 'Ok'
                      })
                    }
                  });
              } else if (this.cardType === 'Cartão Físico') {
                this.$http.post('/users/Unlock', { document, cardNumber: this.card })
                  .then(({ data }) => {
                    this.userExists = data.exists;
                    this.cacheCardToUnlock();
                    if (data.exists === true) {
                    // this.$store.commit('message', { type: 'error', text: 'Usuário já tem cadastro, efetue o login.' });
                    // this.$router.push({ name: 'signIn' });
                    } else if (data.document) {
                      this.cacheCardNumber();
                      if (this.type === 'PF') {
                        this.$store.commit('setUser', Object.assign(data, { tipo: this.type, cpf: data.document }));
                      } else {
                        this.$store.commit('setUser', Object.assign(data, { tipo: this.type, cnpj: data.document }));
                      }
                      this.$router.push({ name: 'signUp' });
                    } else {
                      this.$validator.errors.add({ msg: data.message, field: 'cpf' });
                      this.$validator.errors.add({ msg: data.message, field: 'cnpj' });
                    }
                  }).catch(err => this.callbackError(err));
              }
            }

            if (this.type === 'PF') {
              this.$http.post('users/user-unlocked-card', { cpf: document })
                // eslint-disable-next-line no-unused-vars
                .then(({ data }) => {
                  this.cadastroExistente = data.cadastroExistente;

                  if (this.cadastroExistente) {
                    swal.fire({
                      title: 'Você já possui cadastro na OnlyPay',
                      text: 'Para prosseguir com este desbloqueio, faça login no site ou no aplicativo através de "Acesse seu Cartão" e siga as instruções solicitadas.',
                      type: 'info',
                      confirmButtonText: 'Ok'
                    }).then(() => {
                      this.$router.push({ name: 'signIn' })
                    });
                  } else if (this.cadastroExistente === false) {
                    validateCode();
                  }
                }).catch(err => {
                  return err
                });
            } else {
              this.$http.post('users/user-unlocked-card', { cnpj: document })
                .then(({ data }) => {
                  this.cadastroExistente = data.cadastroExistente;

                  if (this.cadastroExistente) {
                    swal.fire({
                      title: 'Você já possui cadastro',
                      text: 'Para realizar o desbloqueio do seu cartão faça login na sua conta existente e siga o passo-a-passo disponível no FAQ.',
                      type: 'info',
                      confirmButtonText: 'Ok'
                    }).then(() => {
                      this.$router.push({ name: 'signIn' })
                    });
                  } else if (this.cadastroExistente === false) {
                    validateCode();
                  }
                }).catch(err => {
                  if (err) {
                    return err
                  }
                });
            }
          } else this.$store.commit('formLoading', false);
        });
      }
    },
    cacheCardNumber() {
      localStorage.setItem('cardNumber', this.card);
    },
    callbackError(err) {
      if (this.type === 'PF') {
        this.$validator.errors.add({ msg: err.message || err.data.error, field: 'cpf' });
        if (err.indexOf('cadastrado') !== -1) {
          swal.fire({
            title: 'Você já possui cadastro',
            text: 'Para realizar o desbloqueio do seu cartão faça login na sua conta existente e siga o passo-a-passo disponível no FAQ.',
            type: 'info',
            confirmButtonText: 'Ok'
          }).then(() => {
            this.$router.push({ name: 'signIn' })
          });
        }
      } else {
        this.$validator.errors.add({ msg: err.message || err.data.error, field: 'cnpj' });
        if (err.indexOf('cadastrado') !== -1) {
          swal.fire({
            title: 'Você já possui cadastro',
            text: 'Para realizar o desbloqueio do seu cartão faça login na sua conta existente e siga o passo-a-passo disponível no FAQ.',
            type: 'info',
            confirmButtonText: 'Ok'
          }).then(() => {
            this.$router.push({ name: 'signIn' })
          });
        }
      }
    },
    cacheCardToUnlock() {
      const cardToUnlock = this.card;
      localStorage.setItem('isOneCard', this.userExists);
      localStorage.setItem('cardToUnlock', cardToUnlock.substring(19, 15));
    },
    isValidFields() {
      if (this.card.length !== 19 && this.cardType === 'Cartão Físico') {
        this.$validator.errors.add({ msg: 'Número de cartão inválido', field: 'card' });
        return false
      }

      if (this.cpf.length !== 14 && this.type === 'PF') {
        this.$validator.errors.add({ msg: 'Número de CPF inválido', field: 'cpf' });
        return false
      }
      if (this.cnpj.length !== 18 && this.type !== 'PF') {
        this.$validator.errors.add({ msg: 'Número de CNPJ inválido', field: 'cnpj' });
        return false
      }
      return true;
    },
    checkLocalStorageAvaliable() {
      const test = 'test';
      try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
      } catch (e) {
        return false;
      }
    }
  },
};
</script>

<template>
  <div class="unlock">
    <div class="unlock__header">
      <div class="unlock__header--small">
        <a href="https://www.onlypay.com.br" class="unlock__header__logo">
          <img src="~@/assets/img/logo_white.svg" />
        </a>
      </div>
      <div class="unlock__header--large">
        <a href="https://www.onlypay.com.br" class="unlock__header__logo">
          <img src="~@/assets/img/logo_white.svg" />
        </a>
      </div>
    </div>
    <div class="unlock__form">
      <form @submit.prevent="unlock" class="unlock__form__fieldset">
        <p class="unlock__form__fieldset__title" v-if="!userExists" >Desbloqueie seu cartão</p>
        <p class="unlock__form__fieldset__subtitle" v-if="!userExists">
          <span>Olá!</span> para desbloquear seu cartão, digite os dados abaixo:
        </p>
        <div class="unlock__form__fieldset__options" v-if="!userExists">
          <div
            :class="{ 'on': type === 'PF' }"
            @click="type = 'PF'"
            class="unlock__form__fieldset__options__option">
            Pessoa Física
          </div>
          <div
            :class="{ 'on': type === 'PJ' }"
            @click="type = 'PJ'"
            class="unlock__form__fieldset__options__option">
            Pessoa Jurídica
          </div>
        </div>
        <v-textbox
          v-if="type === 'PF' && !userExists"
          :key="type"
          v-model="cpf"
          data-vv-as="CPF"
          label="CPF"
          mask="###.###.###-##"
          name="cpf"
          placeholder="000.000.000-00"
          type="tel"
          validate="required|cpf_format|cpf"/>
        <v-textbox
          v-if="type === 'PJ' && !userExists"
          :key="type"
          v-model="cnpj"
          data-vv-as="CNPJ"
          label="CNPJ"
          mask="##.###.###/####-##"
          name="cnpj"
          placeholder="00.000.000/0000-00"
          type="tel"
          validate="required|cnpj_format|cnpj"/>
          <div class="unlock__form__fieldset__options" v-if="!userExists">
            <div
              :class="{ 'on': cardType === 'Cartão Físico' }"
              @click="cardType = 'Cartão Físico'"
              class="unlock__form__fieldset__options__option">
              Cartão Físico
            </div>
            <div
              :class="{ 'on': cardType === 'Cartão Virtual' }"
              @click="cardType = 'Cartão Virtual'"
              class="unlock__form__fieldset__options__option">
              Cartão Digital
            </div>
          </div>
        <v-textbox
          v-if="cardType === 'Cartão Físico' && !userExists"
          :disabled="userExists"
          v-model="card"
          data-vv-as="Número do cartão"
          label="Número do cartão"
          mask="#### #### #### ####"
          name="card"
          placeholder="0000 0000 0000 0000"
          type="tel"
          validate="required"/>
        <v-textbox
          v-if="cardType === 'Cartão Virtual' && !userExists"
          :key="type"
          id="myInput"
          v-model="codeSended"
          mask="XXXX-XXXX-XXXX"
          data-vv-as="codeSended"
          label="Código de desbloqueio"
          name="codeSended"
          placeholder="0000-0000-0000"
          type="text" />
        <v-button class="unlock__form__fieldset__submit" icon="arrow-right" bgColor="blue" :radius="true" :rebranding="true" label="Verificar" v-if="!userExists" />

        <p class="my-4">Para realizar o desbloqueio do seu cartão faça o login na sua conta existente e siga o passo a passo disponível no FAQ.</p>
        <SignIn page='unlock' v-if="userExists" />
      </form>

      <div class="unlock__footer">
        &copy; 2023 <a href="https://www.onlypay.com.br">OnlyPay</a> - Soluções de pagamento
      </div>
    </div>
    <div class="unlock__aside">
      <h2 class="unlock__aside__text-1">Bem vindo à Only<strong>Pay</strong></h2>
      <h3 class="unlock__aside__text-2">Simples <span>assim!</span></h3>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.unlock {
  height: 100%;
  padding-top: 100px;
  position: relative;
  @media (min-width: 992px) {
    display: flex;
  }
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100px;
    background: var(--color-blue-onlypay);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;

    &__logo {
      size: 8rem 4rem;
      display: flex;
    }
    &--small {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-white);
      width: 100%;
      height: 6rem;
      /* height: 12vh; */
      @media (min-width: 992px) {
        display: none;
      }
    }
    &--large {
      display: none;
      padding: 0 0 0 1.5rem;
      @media (min-width: 992px) {
        display: flex;
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
    background: rgba(255, 255, 255, 0.88);
    position: relative;
    z-index: 10;
    @media (min-width: 992px) {
      background: #EDEEF0;
      box-shadow: 1px 0 1px 0 rgba(0, 0, 0, .2);
      width: 28rem;
    }
    &__fieldset {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      padding: 0 1.5rem;
      @media (max-width: 992px) and (orientation: landscape) {
        padding: 1.5rem;
      }

      &__title {
        display: none;
        font-weight: bold;
        margin-bottom: 1rem;
      }
      &__subtitle {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        color: var(--color-blue-onlypay);
        font-size: 1.3rem;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: 2rem;
        padding-right: 3rem;

        span {
          display: flex;
          font-size: 3.5rem;
          line-height: 5.5rem;
        }
        @media (max-width: 992px) and (orientation: landscape) {
          display: none;
        }
        @media (min-width: 992px) {
          padding-right: 7rem;
        }
      }
      &__options {
        border: .0625rem solid #dde1e8;
        border-radius: .25rem;
        font-size: .75rem;
        font-weight: bold;
        margin-bottom: 2rem;
        &__option {
          background-color: var(--color-grey-lighten);
          color: var(--color-grey-medium);
          cursor: pointer;
          display: inline-block;
          padding: 11px 0 8px;
          text-align: center;
          text-transform: uppercase;
          width: 50%;
          &:not(:last-child) {
            border-right: .0625rem solid var(--color-grey-lighten);
          }
          &.on {
            background-color: var(--color-white);
            color: var(--color-black);
          }
        }
      }
      &__submit {
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        @media (min-width: 992px) {
          display: flex;
          width: 45%;
          margin-top: 2rem;
        }
      }
    }
  }
  &__footer {
    border-top: .0625rem solid var(--color-grey-light);
    font-size: .625rem;
    margin: 0 1.5rem;
    padding: 1rem 0;
  }
  &__aside {
    background-image: url('~@/assets/img/bg_card_unlock.png');
    background-position: center center;
    background-size: cover;
    color: var(--color-white);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @media (min-width: 992px) {
      overflow: hidden;
      width: unset;
      height: unset;
      top: unset;
      left: unset;
      position: relative;
    }
    &__text-1 {
      display: none;
      font-size: 3rem;
      font-weight: 200;
      margin-left: -.125rem;
      padding: .75rem 4rem;
    }
    &__text-2 {
      display: none;
      /* color: var(--color-green);
      font-size: 1.5rem;
      font-weight: 300;
      padding: .75rem 4rem; */
      font-family: 'Trash Hand';
      font-size: 8rem;
      line-height: 6rem;
      opacity: .4;
      position: absolute auto .25em .3em auto;
      transform: rotate(-15deg);
      & span {
        display: block;
        margin-left: 5.5rem;
      }
    }
  }
}
</style>
